.post-excerpt p {
	display:inline;
}

.post-header {
	margin-bottom:2em;
}

.lineno {
	user-select:none;
}

.display-picture {
	display: block;
	margin: auto !important;
	border-radius: 50%;
	width: 100px !important;
	height: 100px !important;
	border: 10px solid #cdcdcd;
	padding: 0px !important;
}

.text-center {
	text-align: center;
	width: fit-content;
    margin: auto;
}

.tag-header {
	text-align: center;
}

.tag-description, .author-description {
	text-align: justify;
}

.tag-description {
	margin-bottom: 4rem;
}

.main-nav {
    padding-bottom: 0px;
}

.google-auto-placed {
	margin-bottom: 4em;
}